import { Vue, Component } from 'vue-property-decorator'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container from 'typedi'
import NotificationService from '@/modules/common/services/notification.service'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'

interface IVTableHeader {
  text: string;
  align?: string;
  searchable?: boolean;
  sortable?: boolean;
  value?: string;
  width?: string;
}

type ExportType = 'links' | 'averages' | 'downloads' | 'speaker-scores' | 'all-answers' | 'all-surveyed-participants' | 'sessions-highlights' | 'all-certificates-transcripts'

interface DownloadProgress {
  total_records: number;
  processed_records: number;
}

type DownloadStatus = 'QUEUED' | 'RUNNING' | 'FINISHED' | 'FAILED' | 'NO_CONTENT'

@Component({
  name: 'GtrSurveysQuizzesView',
  computed: {
    ...mapState('surveys', ['surveysSummaries', 'certificates']),
    ...mapState('event', ['eventAllContent'])
  }
})
export default class GtrSurveysQuizzesView extends GtrSuper {
  surveysSummaries!: any[];
  certificates!: any[];

  // eventAllContent from global state.
  eventAllContent!: any;

  // local state for session search
  sessionSearch: string | null = null

  // headers for the session table
  sessionTableHeaders: IVTableHeader[] = [
    {
      text: 'Session',
      value: 'name'
    },
    {
      text: 'Category',
      value: 'category'
    },
    {
      text: 'Room',
      value: 'room'
    },
    {
      text: 'Start',
      value: 'start_date'
    },
    {
      text: 'End',
      value: 'end_date'
    },
    {
      text: 'Attendees',
      value: 'unique_scans'
    },
    {
      text: 'Emails Sent',
      value: 'survey_emails_count'
    },
    {
      text: 'Completed',
      value: 'surveys_filled_count'
    },
    {
      text: '% Comp.',
      value: 'percent_complete'
    },
    {
      text: '',
      value: 'actions'
    }
  ]

  downloading = false

  downloadProgress: DownloadProgress = {
    total_records: 0,
    processed_records: 0
  }

  async mounted () {
    try {
      await this.fetchSessions()
      await this.fetchCertificates()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchSessions () {
    try {
      await this.$store.dispatch('common/showLoader', { value: true })
      await this.$store.dispatch('surveys/fetchSurveysSummaries', {
        event_uuid: this.$route.params.event_uuid
      })
    } catch (error) {
      if (error.data.error_code === 'CONTENT_NOT_FOUND') {
        Container.get(NotificationService).warning('No session survey found.')
      }
      Container.get(ErrorHandlerService).error(error)
    } finally {
      await this.$store.dispatch('common/hideLoader')
    }
  }

  get eventTimezone (): string {
    return this.$store.state.event?.event?.timezone ?? 'UTC'
  }

  get surveysPortalURL (): string {
    if (this.eventAllContent) {
      return `${process.env.VUE_APP_REG_URL}/${this.eventAllContent.event.event_identifier}/surveys`
    }
    return ''
  }

  private async requestExport (exportType: ExportType) {
    try {
      this.downloading = true
      if (exportType === 'all-surveyed-participants') {
        const response = await this.$store.dispatch('surveys/fetchAllSurveyedParticipantsExport', this.$route.params.event_uuid)
        this.checkURLAndGo(response.data)
      } else if (exportType === 'all-answers') {
        const response = await this.$store.dispatch('surveys/fetchAllAnswersExport', { event_uuid: this.$route.params.event_uuid, session_uuid: undefined })
        this.checkURLAndGo(response.data)
      } else if (exportType === 'sessions-highlights') {
        const response = await this.$store.dispatch('surveys/fetchSessionsHighlightsExport', { event_uuid: this.$route.params.event_uuid })
        this.checkURLAndGo(response.data)
      } else if (exportType === 'all-certificates-transcripts') {
        const response = await this.exportAllCertificatesAndTranscripts()
        switch (response.data.status) {
          case 'FINISHED':
            window.location.href = response.data.s3_url
            break
          case 'NO_CONTENT':
            Container.get(NotificationService).warning('No results')
            break
        }
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.downloading = false
    }
  }

  async exportAnswers (session_uuid) {
    const response = await this.$store.dispatch('surveys/fetchAllAnswersExport', { event_uuid: this.$route.params.event_uuid, session_uuid })
    this.checkURLAndGo(response.data)
  }

  private async fetchCertificates () {
    try {
      const payload = {
        event_uuid: this.$route.params.event_uuid
      }
      const response = await this.$store.dispatch('surveys/getEventCertificates', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async exportAllCertificatesAndTranscripts () {
    try {
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        certificate_uuid: this.certificates[0].uuid
      }
      this.$store.dispatch('surveys/startAllCertificatesAndTranscriptsExport', payload)
      let response, currentResultStatus: DownloadStatus
      do {
        await new Promise(resolve => setTimeout(resolve, 1000))
        response = await this.$store.dispatch('surveys/getAllCertificatesAndTranscriptsExport', payload)
        currentResultStatus = response.data.status
        this.downloadProgress = response.data.status_details
      }
      while (currentResultStatus !== 'FINISHED' && currentResultStatus !== 'NO_CONTENT' && currentResultStatus !== 'FAILED')
      this.downloadProgress = {
        total_records: 0,
        processed_records: 0
      }
      return response
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }
}
